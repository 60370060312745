import { useToast } from '@chakra-ui/react';
import {
   convertCartLineItemsToAnalyticsItem,
   trackInAnalyticsAddToCart,
   trackPiwikCartUpdate,
} from '@ifixit/analytics';
import type { ProductVariantID } from '@ifixit/helpers';
import { SentryError } from '@ifixit/sentry';
import { StorefrontClient, useShopifyStorefrontClient } from '@ifixit/shopify-storefront-client';
import { useIsMutating, useMutation, useQueryClient } from '@tanstack/react-query';
import {
   type AddToCartEventType,
   convertAddToCartInputToAnalyticsItemEvent,
   trackPiwikShopifyAddToCart,
} from '../../helpers/analytics';
import { addToCart, getCart, updateCartLines } from '../../helpers/storefront-api';
import { buildIfixitCart } from '../../models/cart';
import type { Cart, CartLineItem } from '../../types';
import { CART_MUTATION_KEY, cartKeys } from '../../utils';
import { useCart } from './use-cart';
import { useCreateCart } from './use-create-cart';
import { useTranslations } from '@ifixit/i18n';

type AddToCartAnalytics =
   | {
        type: 'product';
        currentItemCode: string;
        handle: string;
        variantid: ProductVariantID;
     }
   | {
        type: 'buy-it-again';
     }
   | {
        type: 'bundle';
        currentItemCode: string;
        handle: string;
        variantid: ProductVariantID;
        itemCodes: string[];
     }
   | {
        type: 'cart-permalink';
     };

export type AddToCartInput = {
   lines: CartLineItem[];
   analytics: AddToCartAnalytics;
};

const META_TYPE = 'add-to-cart';

export function useAddToCart({ eventType }: { eventType?: AddToCartEventType } | undefined = {}) {
   const cart = useCart().data;
   const createCart = useCreateCart();
   const isMutating = useIsMutating({
      mutationKey: CART_MUTATION_KEY,
      predicate: mutation => mutation.meta?.type !== META_TYPE,
   });
   const queryClient = useQueryClient();
   const { client: storefrontClient, currencyCode } = useShopifyStorefrontClient();
   const toast = useToast();
   const t = useTranslations();
   const mutation = useMutation({
      mutationKey: CART_MUTATION_KEY,
      meta: { type: META_TYPE },
      mutationFn: async ({ lines }: AddToCartInput) => {
         if (lines.length === 0 || cart == null) {
            return buildIfixitCart({ cart: null, fallbackCurrencyCode: currencyCode });
         }
         const newCart =
            (cart.shopifyCartId
               ? await performAddToCart({
                    cartId: cart.shopifyCartId,
                    client: storefrontClient,
                    lines,
                 })
               : null) ??
            (await createCart({
               lines: lines.map(({ quantity, shopifyVariantId }) => ({
                  merchandiseId: shopifyVariantId,
                  quantity,
               })),
            }));
         return buildIfixitCart({ cart: newCart, fallbackCurrencyCode: currencyCode });
      },
      onMutate: async ({ lines }: AddToCartInput) => {
         await queryClient.cancelQueries({ queryKey: cartKeys.cart });
         window.onbeforeunload = () =>
            "Some products are being added to the cart. Are you sure you'd like to cancel?";

         const previousCart = queryClient.getQueryData<Cart>(cartKeys.cart);

         queryClient.setQueryData<Cart | undefined>(cartKeys.cart, currentCart => {
            if (currentCart == null) {
               return currentCart;
            }
            const updatedCart = lines.reduce((cart, line) => addLineItem(cart, line), currentCart);

            return updatedCart;
         });

         return { previousCart };
      },
      onError: (error, variables, context) => {
         queryClient.setQueryData<Cart | undefined>(cartKeys.cart, context?.previousCart);
         toast.closeAll();
         if (
            error instanceof SentryError &&
            Array.isArray(error.sentryDetails.extra?.validationErrors) &&
            error.sentryDetails.extra.validationErrors.length > 0
         ) {
            error.sentryDetails.extra.validationErrors.forEach(error =>
               toast({
                  id: 'add-to-cart-error',
                  status: 'error',
                  title: error,
                  isClosable: true,
                  variant: 'subtle',
                  position: 'bottom-right',
               })
            );
         } else {
            toast({
               id: 'add-to-cart-error',
               status: 'error',
               title: t('ErrorHandling.unableToAddProduct'),
               description: t('ErrorHandling.tryActionAgain'),
               isClosable: true,
               variant: 'subtle',
               position: 'bottom-right',
            });
         }
      },
      onSuccess: (cart, variables) => {
         trackPiwikShopifyAddToCart(variables, { eventType });
         const event = convertAddToCartInputToAnalyticsItemEvent(variables);
         trackInAnalyticsAddToCart(event);
         trackPiwikCartUpdate({
            items: convertCartLineItemsToAnalyticsItem(cart.lineItems),
            value: Number(cart.totals.price.amount),
            currency: cart.totals.price.currencyCode,
         });
      },
      onSettled: () => {
         window.onbeforeunload = () => {};
         return queryClient.invalidateQueries({ queryKey: cartKeys.cart });
      },
   });
   return { addToCart: mutation, enabled: cart != null && !isMutating };
}

export async function performAddToCart({
   cartId,
   client,
   lines,
}: { client: StorefrontClient; cartId: string; lines: CartLineItem[] }) {
   const cart = await getCart(client, { cartId });
   const lineEdges = cart?.lines.edges ?? [];
   const linesToUpdate =
      cart == null
         ? []
         : lines
              .map(line => {
                 const existingLine = lineEdges.find(
                    ({ node }) => node.merchandise.id === line.shopifyVariantId
                 )?.node;
                 const existingQuantity = existingLine?.quantity ?? 0;
                 return existingLine?.id
                    ? {
                         id: existingLine.id,
                         merchandiseId: line.shopifyVariantId,
                         quantity: existingQuantity + line.quantity,
                      }
                    : null;
              })
              .filter(<T,>(line: T | null): line is T => line != null);
   const linesToAdd = lines
      .filter(line => !lineEdges.some(({ node }) => node.merchandise.id === line.shopifyVariantId))
      .map(({ shopifyVariantId, quantity }) => ({
         merchandiseId: shopifyVariantId,
         quantity,
      }));
   let newCart = cart;
   if (linesToUpdate.length > 0) {
      newCart = await updateCartLines(client, { cartId, lines: linesToUpdate });
   }
   if (linesToAdd.length > 0) {
      newCart = await addToCart(client, { cartId, lines: linesToAdd });
   }
   return newCart;
}

function addLineItem(cart: Cart, inputLineItem: CartLineItem): Cart {
   const currentLineItemIndex = cart.lineItems.findIndex(
      item => item.itemcode === inputLineItem.itemcode
   );
   const isAlreadyInCart = currentLineItemIndex !== -1;
   const updatedLineItems = [...cart.lineItems];
   if (isAlreadyInCart) {
      const currentLineItem = cart.lineItems[currentLineItemIndex];
      const updatedQuantity = currentLineItem.quantity + inputLineItem.quantity;
      updatedLineItems.splice(currentLineItemIndex, 1, {
         ...currentLineItem,
         quantity: updatedQuantity,
      });
   } else {
      // Match Shopify's behavior of prependng new items to the cart
      updatedLineItems.unshift(inputLineItem);
   }
   const updateTotalPrice = Math.max(
      Number(cart.totals.price.amount) +
         inputLineItem.quantity * Number(inputLineItem.price.amount),
      0
   ).toFixed(2);
   return {
      ...cart,
      hasItemsInCart: true,
      isEmpty: false,
      lineItems: updatedLineItems,
      totals: {
         ...cart.totals,
         price: {
            ...cart.totals.price,
            amount: updateTotalPrice,
         },
         itemsCount: cart.totals.itemsCount + inputLineItem.quantity,
      },
   };
}
